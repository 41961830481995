
import { PROMPT_OPEN } from "@/store/mutation-types";
import VqInput from "@/components/widgets/VqInput/VqInput.vue";
import Top from "@/components/widgets/Top/Top.vue";
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";

const promptModule = namespace("prompt");

@Options({
  components: { VqInput, Top },
})
export default class Notification extends Vue {
  @promptModule.Action(PROMPT_OPEN) private onPrompt!: ({
    title,
    content,
  }: {
    title: string;
    content: string;
  }) => Promise<any>;

  private notificationList = [
    {
      id: 0,
      icon: "icon_notification_push",
      title: "推送消息",
      subTitle: "您的实盘「火币网格交易实盘」，昨日收益为",
      date: "12:58",
    },
    {
      id: 1,
      icon: "icon_notification_warn",
      title: "告警消息",
      subTitle: "实盘「火币网格交易实盘」已强制停止，报错",
      date: "12:58",
    },
    {
      id: 2,
      icon: "icon_notification_update",
      title: "动态消息",
      subTitle: "稳赢外汇 在评论中提到了你",
      date: "12:58",
    },
    {
      id: 3,
      icon: "icon_notification_web",
      title: "站内消息",
      subTitle: "关于《VeryQuant海龟策略使用条款》",
      date: "12:58",
    },
    {
      id: 4,
      icon: "icon_notification_ticket",
      title: "工单消息",
      subTitle: "暂无消息",
      date: "12:58",
    },
  ];

  private handleReadClicked(): void {
    this.onPrompt({
      title: "一键已读",
      content: "将全部消息设置为已读",
    })
      .then(() => {
        console.log("confirm");
      })
      .catch(() => {
        console.log("catch");
      });
  }
}
