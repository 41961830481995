import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5316aaa5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Notification" }
const _hoisted_2 = { class: "topBtns" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "NotificationItem__content" }
const _hoisted_5 = { class: "NotificationItemTitle" }
const _hoisted_6 = { class: "NotificationItemSubtitle" }
const _hoisted_7 = { class: "NotificationItem__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Top = _resolveComponent("Top")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Top, { title: '消息中心' }, {
      addonAfter: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "topBtn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleReadClicked && _ctx.handleReadClicked(...args)))
          }, "全部已读")
        ])
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "NotificationItem",
        key: item.id
      }, [
        _createElementVNode("img", {
          class: "NotificationItem__icon",
          src: require(`@/assets/img/notification/${item.icon}.png`)
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(item.subTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_7, _toDisplayString(item.date), 1)
      ]))
    }), 128))
  ]))
}